import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Heading from '../../../../components/Heading/DesktopView';
import ProductCard from '../../../../components/ProductCard';

// Partials
import useStockGoods from '../useStockGoods';

// Styles
import styles from './StockGoods.styl';

const cx = classNames.bind(styles);

export default function StockGoods(props) {
  const { stockRole } = props;

  const {
    stockGoodsRef,
    title,
    filtredGoods,
    removeIngredientAction,
    cancelRemovedIngredientsAction,
    addToCart,
    type,
  } = useStockGoods({ stockRole });

  return (
    <section className={cx('StockGoods')} ref={stockGoodsRef}>
      <Heading className={cx('StockGoods__heading')} level={2} tagName="h4">
        {title}
      </Heading>
      <div className={cx('StockGoods__list')}>
        {filtredGoods.map(good => (
          <ProductCard
            className={cx('StockGoods__item')}
            key={good.alias}
            good={good}
            stockRole={stockRole}
            removeIngredientAction={removeIngredientAction}
            cancelRemovedIngredientsAction={cancelRemovedIngredientsAction}
            addToCart={addToCart}
            type={type}
          />
        ))}
      </div>
    </section>
  );
}

StockGoods.propTypes = {
  stockRole: PropTypes.oneOf(['conditions', 'rewards']).isRequired,
};

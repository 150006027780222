import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

//Components
import Modal from '../../../../components/Modal';
import Content from '../partials';

//Styles
import styles from './ModalNewSite.styl';

const cx = classNames.bind(styles);

const ModalNewSite = ({ isOpen, setIsOpen }) => {

  const onCloseModal = useCallback(() => {
   setIsOpen(false);
  }, []);

  useEffect(() => {
    window.localStorage.setItem('showNewSiteModal', 'true');
  }, []);

  return (
    <Modal
      className={cx('ModalContainer__modal')}
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      <Content onClose={onCloseModal} />
    </Modal>
  );
};

export default ModalNewSite;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { currentLoyaltySelectorForNewPL, daysResetStatus } from '../../../state/modules/user/selectors';

// Components
import Container from '../../../components/Container';

// Partials
import useHome from '../useHome';
import ThanksForOrderingLink from '../ThanksForOrderingLink';
import DeliveryAlert from '../DeliveryAlert';
import MainSlider from '../MainSlider';
import MainSidebar from '../MainSidebar';
import SEO from '../SEO';
import StockGoods from '../StockGoods/DesktopView';
import FreeSaucesPanel from '../FreeSaucesPanel/DesktopView';
import CategoryIcons from '../CategoryIcons';
import ProductList from '../ProductList/DesktopView';
import Header from '../Header/DesktopView';
import PopupGeneral from '../PopupGeneral';
import ModalNotAuth from '../ModalNotAuth/DesktopView';
import ModalNotEnough from '../ModalNotEnought/DesktopView';
import ModalNewLoyaltyProgramm from '../ModalNewLoyaltyProgramm/DesktopView';

//Actions
import { changeVpnModal } from '../../../state/modules/homeModals/actions';
import { fetchProfile } from '../../../state/modules/user/actions';
//Icons
import VPNIcons from '../../../icons/icon-vpn.svg';

// Styles
import styles from './Home.styl';
import config from '../../../config';
import ModalNewSite from '../ModalNewSite/DesktopView';

const cx = classNames.bind(styles);

export default function HomePage(props) {
  const SESSION_STORAGE_KEY_VPN = 'modal-vpn';
  const LOCAL_STORAGE_KEY_LOYALTY = 'showLoyaltyModal';
  const LOCAL_STORAGE_KEY_NEW_SITE = 'showNewSiteModal';
  const dispatch = useDispatch();

  const intl = useIntl();
  const location = useLocation();

  const { stockRole } = props;
  const topRef = useRef(null);
  const widthRef = useRef(null);

  const { isHomeCatalogVisible, categories } = useHome(topRef);

  const openModalVpn = useSelector(state => state.homeModal.isModalVpn);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const { name, declension } = useSelector(state => state.city.userCity);
  const isNewYearMode = useSelector(state => !!state.city.userCity.is_new_year_mode_on);

  const currentLoyaltyForOldPL = useSelector(currentLoyaltySelectorForNewPL);
  const daysResetPL = useSelector(daysResetStatus);

  const condition = useMemo(() => {
    return currentLoyaltyForOldPL && daysResetPL > 0 && isAuthenticated;
  }, [currentLoyaltyForOldPL, daysResetPL, isAuthenticated]);

  const conditionForModalNewSite = useMemo(() => {
    const currentDate = new Date();
    const targetDate = new Date('2024-01-01');
    return currentDate < targetDate ;
  }, []);

  const [isOpenModalBonus, setIsOpenModalBonus] = useState(false);
  const [isOpenModalAuth, setIsOpenModalAuth] = useState(false);
  const [isOpenModalPL, setIsOpenModalPL] = useState(false);
  const [isOpenModalNewSite, setIsOpenModalNewSite] = useState(false);

  const handleOpenModalPL = () => {
    setIsOpenModalPL(true);
  };

  const handleOpenModalNewSite = () => {
    setIsOpenModalNewSite(true);
  };

  const closeModalVpn = () => {
    sessionStorage.setItem(SESSION_STORAGE_KEY_VPN, 'false');
    dispatch(changeVpnModal(false));
  };

  useEffect(() => {
    !window.localStorage.getItem(LOCAL_STORAGE_KEY_LOYALTY) &&
    condition && handleOpenModalPL();
  }, [condition]);

  useEffect(() => {
    !window.localStorage.getItem(LOCAL_STORAGE_KEY_NEW_SITE) &&
    conditionForModalNewSite && handleOpenModalNewSite();
  }, [conditionForModalNewSite]);

  useEffect(() => {
    (sessionStorage.getItem(SESSION_STORAGE_KEY_VPN) !== null) && dispatch(changeVpnModal(JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY_VPN))));
  }, []);

  useEffect(() => {
    if (isAuthenticated) dispatch(fetchProfile());
  }, [isAuthenticated]);

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'page.menu.helmet' }, { declension, name });
  }, [location.search]);

  return (
    <div className={cx('HomePage', { 'HomePage__new-year': isNewYearMode })} ref={widthRef}>
      {isNewYearMode && <div className={cx('HomePage__header')} />}
      <MainSlider topRef={topRef} view="desktop" />
      <div className={cx('HomePage__container')}>
        <Container>
          {!stockRole && <CategoryIcons className={cx('HomePage__menu')} />}
          <div ref={topRef} style={{ height: '1px' }} />
          <MainSidebar />

          <div
            className={cx('HomePage__content', { HomePage__content_hidden: !isHomeCatalogVisible })}
          >

            {stockRole ? (
              <StockGoods stockRole={stockRole} />
            ) : (
              <ProductList
                setIsOpenModalBonus={setIsOpenModalBonus}
                setIsOpenModalAuth={setIsOpenModalAuth}
                category={categories} />
            )}
            <SEO />
          </div>
        </Container>
        {!stockRole && <Header />}
        <DeliveryAlert />
        <FreeSaucesPanel />
        <ThanksForOrderingLink />
        <ModalNotAuth isOpenModalAuth={isOpenModalAuth}
                      setIsOpenModalAuth={setIsOpenModalAuth} />
        {isOpenModalPL &&
          <ModalNewLoyaltyProgramm
            isOpen={isOpenModalPL}
            setIsOpen={setIsOpenModalPL}
          />
        }
        {isOpenModalNewSite &&
          <ModalNewSite
            isOpen={isOpenModalNewSite}
            setIsOpen={setIsOpenModalNewSite}
          />
        }
        <ModalNotEnough
          title={intl.formatMessage(
            { id: 'profile.papabonuses.notEnough' },
          )}
          text={intl.formatMessage(
            { id: 'profile.papabonuses.payment' },
          )}
          isOpenModalBonus={isOpenModalBonus}
          setIsOpenModalBonus={setIsOpenModalBonus}
        />
        {openModalVpn && !isOpenModalPL && config.legal !== 'de' && (
          <PopupGeneral
            text={intl.formatMessage({ id: 'vpn.modal' })}
            icon={<VPNIcons />}
            closeModal={closeModalVpn}
            className={cx('ModalVpn__popupMobileGeneral_desktop')}
            classNameIcon={cx('ModalVpn__popupMobileGeneral_icon')}
          />)}
      </div>
    </div>
  );
}

HomePage.defaultProps = {
  stockRole: '',
};

HomePage.propTypes = {
  stockRole: PropTypes.oneOf(['conditions', 'rewards', '']),
};

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import CategoryNavItem from '../../../components/CategoryNavItem';

// Icons
import PizzaData from './assets/menu/pizza.svg';
import NapitkiData from './assets/menu/drinks.svg';
import ZakuskiData from './assets/menu/snacks.svg';
import ComboboxData from './assets/menu/combobox.svg';
import DesertyData from './assets/menu/dessert.svg';
import SalatsData from './assets/menu/salads.svg';
import SousesData from './assets/menu/sauces.svg';
import OtherData from './assets/menu/other.svg';
import HotData from './assets/menu/hot.svg';
import PapadiasData from './assets/menu/papadias.svg';
import VeganData from './assets/menu/vegan.svg';

// Styles
import styles from './CategoryIcons.styl';

const cx = classNames.bind(styles);

const iconsData = {
  pizza: PizzaData,
  napitki: NapitkiData,
  zakuski: ZakuskiData,
  salaty: SalatsData,
  deserty: DesertyData,
  sauce: SousesData,
  combo: ComboboxData,
  combobox: ComboboxData,
  other: OtherData,
  hot: HotData,
  papadias: PapadiasData,
  vegan: VeganData,
};

export default function CategoryIcons(props) {
  const { className } = props;

  const categoriesAliases = useSelector(state => state.catalog.products.categoriesAliases);

  return (
    <div className={cx('CategoryIcons', className)}>
      {Object.keys(categoriesAliases).map(alias => (
        <CategoryNavItem
          key={alias}
          className={cx('CategoryIcons__link', `CategoryIcons__link_${alias}`)}
          alias={alias}
          // style={{ width: `${100 / Object.keys(categoriesAliases).length}%` }}
        >
          {/*<img*/}
          {/*  className={cx('CategoryIcons__icon', `CategoryIcons__icon_${alias}`)}*/}
          {/*  alt={alias}*/}
          {/*  src={iconsData[alias] || iconsData.pizza}*/}
          {/*/>*/}
          <span className={cx('CategoryIcons__name')}>{categoriesAliases[alias]}</span>
        </CategoryNavItem>
      ))}
    </div>
  );
}

CategoryIcons.defaultProps = {
  className: '',
};

CategoryIcons.propTypes = {
  className: PropTypes.string,
};
